<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 sm10 py-10>
        <v-card outlined color="#FFBABA">
          <v-layout wrap pa-5 justify-center>
            <v-flex xs12 text-left>
              <span style="color: #b71818; font-family: poppinssemibold"
                >Rules to follow</span
              >
            </v-flex>
            <v-flex xs12 pt-2 text-left v-if="exam.rules">
              <span
                style="
                  color: #101010;
                  font-size: 14px;
                  font-family: poppinsregular;
                "
                v-html="exam.rules.examRule"></span
              >
            </v-flex>
          </v-layout>
        </v-card>
        <v-layout wrap justify-center pt-5 v-if="exam.id">
          <v-flex xs12>
            <v-btn tile color="#3CB043" dark  :to="'/examSection?id=' + exam.id + '&courseId=' + $route.query.courseId"
              ><span style="text-transform: none; font-family: opensanssemibold"
                >Start Exam</span
              ></v-btn
            >
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      exam: [],
      dirty_form: true,
      showDialog: false,
      startTime: null,
      currentTime: 0,
      course:"",
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/exam/set/questions",
        method: "POST",
        data: {
          courseId: this.$route.query.courseId,
          combinationCourseId: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.exam = response.data;
          this.course=response.data.course
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>